import React from "react";

const Gallery = () => {
    return (
        <div>
        <h1>Hello, I'm Gallery component</h1>
        <div>
          <img src="https://picsum.photos/200/300" alt="image0" />
          <img src="https://picsum.photos/200/300" alt="image1" />
          <img src="https://picsum.photos/200/300" alt="image2" />
          <img src="https://picsum.photos/200/300" alt="image3" />
          <img src="https://picsum.photos/200/300" alt="image4" />
        </div>
      </div>
    );
};

export default Gallery;