import React, { Fragment } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";import Home from './Components/Home';
import About from './Components/About';
import Gallery from './Components/Gallery';

function App() {
  return (
    <Fragment>
      <Router>
        <div>
          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/gallery">Gallery</Link>
              </li>
            </ul>
          </nav>
        </div>

        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
      <footer>Hello I'm a little footer</footer>
    </Fragment>
  );
}

export default App;